import {
  CheckIcon,
  EyeIcon,
  LinkIcon,
  PencilSquareIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from '../../../components/PageWrapper';
import clsx from 'clsx';
import RedactInfo from '../../../components/elements/RedactInfo';
import ToolTip from '../../../components/ToolTip';
import moment from 'moment';
import IconStatus from '../../../components/elements/IconStatus';
import useRoute from '../../../hooks/routesHook';
import {
  capitalizeSentence,
  getFormattedPaymentChannel,
  getPaystackConfig,
  getPhoneNumberFromShortCodeFormat,
  getSelectOptionsFromEnum,
  numberWithCommas,
  sentenceCase
} from '../../../utils/miscFunctions';
import { ACTIVITY_STREAMS_TYPE, PAYMENT_STATUS_STATES, POLICY_DOCUMENT_TYPES, POLICY_REVIEW_STATES, REDACT_INFO_TYPES, RNPL_PAYMENT_CHANNEL } from '../../../resources/enums';
import useUserAccount from '../../../hooks/userAccountHook';
import {
  approveRNPLApplication,
  downloadPolicyDocument,
  generateRNPLPayment,
  getAllRNPL,
  getPolicy,
  getRNPLPayment,
  getSingleRNPLCustomer,
  rnplPreapproval
} from '../../../api/requests';
import BackButton from '../../../components/BackButton';
import PolicyStatus from '../../../components/elements/PolicyStatus';
import { MdChangeCircle } from 'react-icons/md';
import PopOnMe from '../../../components/elements/poponme';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import TextArea from '../../../../src/components/form-control/TextArea';
import Loader from '../../../../src/components/Loader';
import { IPolicyDocument } from '../../../resources/interfaces';
import { HiDownload } from 'react-icons/hi';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import usePayment from '../../../hooks/paymentHook';
import { PaystackProps } from 'react-paystack/dist/types';
import useModal from '../../../hooks/modalHook';
import EditRNPL from './EditRNPL';
import GTable from '../../../components/elements/GTable';
import MyPopOver from '../../../components/elements/PopOver';
import DiscountInfoModal from '../policies/DiscountInfoModal';
import { FaEye } from 'react-icons/fa';
import TabContent from '../../../components/tabs/TabContent';
import { INPUT_DATE_FORMAT } from '../../../resources/constants';
import Tooltip from '../../../components/ToolTip';
import PaymentStatus from '../../../components/PaymentStatus';
import TabNavItem from '../../../components/tabs/TabNavItem';
import ActionConfirmation from '../../../components/elements/ActionConfirmation';
import { NEW_OWNER_YET_TO_CHANGE_AT_DVLA } from '../policies/PolicyEdit';
import { ITableAction } from '../../../components/elements/GTable/GTable';
import ActivityStepper from '../../../components/ActivityStepper';
import HubtelPreapprovalModal from '../payments/HubtelPreapprovalModal';
import RNPLPreapproval from './RNLPPreapprovalModal';
import EditReviewStage from './EditReviewStage';
import { Policy } from '../../../models/policy/Policy';

interface IPolicyDetailsProps {
  previousPath: string;
}

const RNPLApplicantDetails = (props: IPolicyDetailsProps) => {
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const { user } = useUserAccount();
  const [RNPLApllicant, setRNPLApllicant] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const rnplId = params?.id;
  const queryClient = useQueryClient();
  //const paystackConfig: PaystackProps = getPaystackConfig(RNPLApllicant);
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const [payments, setPayments] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('payments');

  const displayRNPLApplicant = () => {
    rnplId &&
    getSingleRNPLCustomer(rnplId)
        .then((response: any) => {
          setRNPLApllicant(response?.data); 
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };
 
  const {} = useQuery<any, Error>({
    queryKey: ['displayRNPLApplicant'],
    queryFn: displayRNPLApplicant
  });

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['displayRNPLApplicant'] });
  };

 
const totalPaymentsAmount = () => {
  const amount = payments && payments?.map((payment: any, i: number) => {
    return payment?.paymentStatus == PAYMENT_STATUS_STATES.SUCCESS && payment?.amount;
  });

  let startVal = 0;
  let x = 0;
  while (x < amount.length) {
    startVal = startVal + amount[x];
    x++;
  }

  return startVal;
};

  
  const allRNPLPayments = () => {
    
    rnplId &&
      getRNPLPayment(String(rnplId))
        .then((response: any) => {
          setPayments(response?.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
  };
  const orderPaymentsById = payments.sort((a:any,b:any)=>a.id -b.id)
  const {} = useQuery<any, Error>({
    queryKey: ['allRNPLPayments'],
    queryFn: allRNPLPayments
  });

  const invalidateQueriesPayments = () => {
    queryClient.invalidateQueries({ queryKey: ['allRNPLPayments'] });
  };

  const localDate = new Date();
  const paymentDate = localDate ? moment(localDate).format(INPUT_DATE_FORMAT) : '';


  const approveRNPL = () => {
    updateModalStates({
      show: true,
      showCloseButton: false,
      children: (
        <ActionConfirmation 
          action="Approve"
          resourceType={''}
          resourceName={''}
          onClose={closeModal}
          onConfirm={() => {
            approveRNPLApplication(RNPLApllicant.id)
            .then((response: any) => {
              toast.success(response?.message && 'RNPL updated successfully.');
              closeModal();
              invalidateQueries();
            })
            .catch((error: any) => {
              toast.success(error?.message && 'RNPL updated error Encountered.');
            });
          }}
        />
      )
    });
  };

  const editReviewStage = (rnpl:any) => {
    RNPLApllicant &&
      updateModalStates({
        show: true,
        size: '2xl',
        children: (
          <EditReviewStage
            rnplApplicant={rnpl}
            onProceed={() => { 
              closeModal(); 
              invalidateQueries();
            }}
            onClose={closeModal}  
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const getActions = () => {
    const policyActions = [
      {
        label: 'Generate payment schedules',
        callback: () => {
          generateRNPLPayment(String(rnplId), paymentDate)
            .then((response: any) => { 
              toast.success(response?.message && 'Payment Generated successfully.');
              invalidateQueriesPayments();
            })
            .catch((error: any) => {
              toast.error(error?.message && 'Generate payment has failed.');
              console.log(error)
            });
        }
      }
    ];

    if (RNPLApllicant) {
      RNPLApllicant.reviewStage !== POLICY_REVIEW_STATES.APPROVED && user.isSuperAdmin() && policyActions.push({
        label: `Approve RNPL`,
        callback: () => {
          approveRNPL()
        }
      });

      RNPLApllicant.reviewStage !== POLICY_REVIEW_STATES.APPROVED && policyActions.push({
        label: `Review RNPL Application`,
        callback: () => {
          editReviewStage(RNPLApllicant)
        }
      });
    }

    return policyActions;
  };

   const getRowActions = (payment: any): ITableAction[] => {
      const actions = [];
  
      if (payment && payment?.paymentStatus !== PAYMENT_STATUS_STATES.SUCCESS) {
        actions.push(
          {
            label: 'Get paystack payment link',
            callback: () => {
              if (payment) generatePaymentLink(payment.id);
            }
          },
          {
            label: 'Send payment notification',
            callback: () => {
              if (payment) sendPaymentNotification(payment);
            }
          },
          {
            label: 'Initiate direct charge',
            callback: () => {
              if (payment) {
                InitiateHubtelDirectCharge(payment);
              }
            }
          }
        );
  
        if (user.isReviewer()) {
          actions.push({
            label: 'Edit',
            callback: () => {
              if (payment) showEditPaymentModal(payment);
            }
          });
        }
  
        if (payment?.paymentStatus == PAYMENT_STATUS_STATES.APPROVAL && user.isSuperAdmin())
          actions.push({
            label: 'Approve',
            callback: () => {
              if (payment) showPaymentApprovalModal(payment);
            }
          });
      }
  
      return actions;
    };

  const isOnCollateral = () => {
    switch (RNPLApllicant.vehicles?.natureOfInterest) {
      case 'Hire Purchaser':
        return 'YES';
      case 'Work & Pay driver':
        return 'YES';
      default:
        return 'NO';
    }
  };
  const isCarOwner = () => {
    switch (RNPLApllicant.vehicles?.natureOfInterest) {
      case NEW_OWNER_YET_TO_CHANGE_AT_DVLA:
        return 'YES';
      default:
        return 'NO';
    }
  };

  const editRNPL= (section: string) => {
    RNPLApllicant &&
      updateModalStates({
        show: true,
        size: '2xl',
        children: (
          <EditRNPL
            rnplApplicant={RNPLApllicant}
            onProceed={() => {
              closeModal();
              invalidateQueries();
            }}
            onClose={closeModal}
            section={section}
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const rnplPreapproval = () => {
    RNPLApllicant &&
      updateModalStates({
        show: true,
        size: 'xl',
        children: (
          <RNPLPreapproval
            rnplApplicant={RNPLApllicant}
            onProceed={() => {
              closeModal();
              invalidateQueries();
            }}
            onClose={closeModal} 
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };


  const otherInfo = () => {
    return (
      <div className="mt-5">
        <span className="font-semibold">Other Information</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2  rounded-lg h-[230px] md:grid-cols-1 w-full '
          )}>
          <RNPLDetailsItem name={'Is vehicle on collateral?'} value={isOnCollateral()} />
          <RNPLDetailsItem name={'Are you the owner of the vehicle?'} value={isCarOwner()} />
          
          <RNPLDetailsItem
            name={'Does owner consent to RNPL?'}
            value={String(RNPLApllicant?.ownerConsent === true ? 'YES' : 'NO') ?? 'N/A'}
          />
            <RNPLDetailsItem>
              <TextArea
                name={'description'}
                label="Damage Description"
                value={RNPLApllicant.repairDescription}
                disabled={true}
                classes="italic"
              />
            </RNPLDetailsItem>
        </div>
      </div>
    );
  };
  const getProposer = (): JSX.Element => {
    return (
      <div className="mt-5">
        <span className="font-semibold">{'Proposer'}</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2 rounded-lg h-full',
            user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-2 grid-cols-1 gap-5'
          )}>
          <div className="">
            <div className="flex flex-row justify-between py-1 ">
              <div className="text-gray-600">Name:</div>
              {capitalizeSentence(
                      RNPLApllicant.customer?.firstName + ' ' + RNPLApllicant.customer?.lastName
                    ) ?? 'N/A'}
            </div>

            <RNPLDetailsItem
              name="Phone"
              value={ 
                  <RedactInfo
                    infoType={REDACT_INFO_TYPES.PHONE}
                    info={getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.phone) ?? 'N/A'}
                    dataId={RNPLApllicant?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  /> 
              }/>

            <RNPLDetailsItem
              name="Other Phone"
              value={
                RNPLApllicant.customer?.otherPhone? (
                  <RedactInfo
                    infoType={REDACT_INFO_TYPES.PHONE}
                    info={getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.otherPhone) ?? 'N/A'}
                    dataId={RNPLApllicant?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.otherPhone ?? '')
                )
              }/>

            <RNPLDetailsItem name="Email" value={RNPLApllicant?.customer.email ?? ''} />

            <RNPLDetailsItem name="Address">
              <ToolTip
                message={
                  <RedactInfo
                    info={RNPLApllicant?.customer?.address ?? ''}
                    totalToRedact={10}
                    dataId={RNPLApllicant?.ref ?? ''}
                    dataType="policy"
                  />
                }>
                <div className="font-medium truncate w-full max-w-32">
                  {RNPLApllicant?.customer?.address && RNPLApllicant?.active ? (
                    <RedactInfo
                      info={RNPLApllicant?.customer?.address}
                      totalToRedact={10}
                      dataId={RNPLApllicant?.ref ?? ''}
                      dataType="policy"
                    />
                  ) : (
                    RNPLApllicant?.customer?.address ?? 'N/A'
                  )}
                </div>
              </ToolTip>
            </RNPLDetailsItem>

            <RNPLDetailsItem
              name="Date of Birth"
              value={
                RNPLApllicant?.customer?.dateOfBirth ? (
                  <RedactInfo
                    info={moment(RNPLApllicant?.customer.dateOfBirth).format('DD-MM-YYYY')}
                    totalToRedact={6}
                    dataId={RNPLApllicant?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  moment(RNPLApllicant?.customer.dateOfBirth).format('DD-MM-YYYY') ?? 'N/A'
                )
              }
            />

            <>
              <RNPLDetailsItem
                name="Do you have any other liabilities?"
                value={RNPLApllicant?.customer?.liability ? 'Yes' : 'No'}
              />
              {RNPLApllicant?.customer?.liability && (
                <>
                  <RNPLDetailsItem
                    name="How much are you owing?"
                    value={RNPLApllicant?.customer?.liabilityDebt ?? ''}
                  />
                </>
              )}
            </>
          </div>

          <div className="">
            <RNPLDetailsItem
              name="ID Type"
              value={RNPLApllicant?.customer?.idType ? capitalizeSentence(RNPLApllicant?.customer?.idType) : ''}
            />

            {!user.isInsurer() && (
              <RNPLDetailsItem
                name="ID Expiry Date"
                value={moment(RNPLApllicant?.customer?.idExpiry ?? '').format('DD-MM-YYYY')}
              />
            )}

            {!user.isInsurer() && (
              <>
                <RNPLDetailsItem
                  name="Occupation"
                  value={RNPLApllicant?.customer?.occupation ?? 'N/A'}
                />

                <RNPLDetailsItem
                  name="Employer Name"
                  value={RNPLApllicant?.customer?.employerName ?? 'N/A'}
                />
                <div className="flex flex-row justify-between ">
                  <span className="text-gray-600">Employer Address:</span>
                  <span className="truncate font-medium max-w-52">
                    {RNPLApllicant?.customer?.employerAddress ?? 'N/A'}
                  </span>
                </div>

                {/* <PolicyDetailsItem
                  name="Employer Address"
                  value={policy?.applicant?.employerAddress ?? 'N/A'}
                /> */}

                <RNPLDetailsItem
                  name="Employer Phone Number"
                  value={
                    getPhoneNumberFromShortCodeFormat(RNPLApllicant?.customer?.employerPhone) ??
                    'N/A'
                  }
                />

                <RNPLDetailsItem
                  name="Monthly Income Band"
                  value={RNPLApllicant?.customer?.monthlyIncomeBand ?? 'N/A'}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  // const getProposer = (): JSX.Element => {
  //   return (
  //     <div className="mt-5">
  //       <span className="font-semibold">{'Proposer'}</span>
  //       <div
  //         className={clsx(
  //           'grid border border-gray-300 p-2 rounded-lg h-full',
  //           user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-2 grid-cols-1 gap-5'
  //         )}>
  //         <div className="">
  //           <div className="flex flex-row justify-between py-1 ">
  //             <div className="text-gray-600">Name:</div>
  //             {capitalizeSentence(
  //                     RNPLApllicant.customer?.firstName + ' ' + RNPLApllicant.customer?.lastName
  //                   ) ?? 'N/A'}
  //           </div>

  //           <RNPLDetailsItem
  //             name="Phone"
  //             value={ 
  //                 <RedactInfo
  //                   infoType={REDACT_INFO_TYPES.PHONE}
  //                   info={getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.phone) ?? 'N/A'}
  //                   dataId={RNPLApllicant?.ref ?? ''}
  //                   dataType="policy"
  //                   clickable={!user.isInsurer() && true}
  //                 /> 
  //             }/>

  //           <RNPLDetailsItem
  //             name="Other Phone"
  //             value={
  //               RNPLApllicant.customer?.otherPhone? (
  //                 <RedactInfo
  //                   infoType={REDACT_INFO_TYPES.PHONE}
  //                   info={getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.otherPhone) ?? 'N/A'}
  //                   dataId={RNPLApllicant?.ref ?? ''}
  //                   dataType="policy"
  //                   clickable={!user.isInsurer() && true}
  //                 />
  //               ) : (
  //                 getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.otherPhone ?? '')
  //               )
  //             }></RNPLDetailsItem>

  //           <RNPLDetailsItem name="Email" value={RNPLApllicant?.customer.email ?? ''} />

  //           <RNPLDetailsItem name="Address">
  //             <ToolTip
  //               message={
  //                 <RedactInfo
  //                   info={RNPLApllicant?.customer?.address ?? ''}
  //                   totalToRedact={10}
  //                   dataId={RNPLApllicant?.ref ?? ''}
  //                   dataType="policy"
  //                 />
  //               }>
  //               <div className="font-medium truncate w-full max-w-32">
  //                 {RNPLApllicant?.customer?.address && RNPLApllicant?.active ? (
  //                   <RedactInfo
  //                     info={RNPLApllicant?.customer?.address}
  //                     totalToRedact={10}
  //                     dataId={RNPLApllicant?.ref ?? ''}
  //                     dataType="policy"
  //                   />
  //                 ) : (
  //                   RNPLApllicant?.customer?.address ?? 'N/A'
  //                 )}
  //               </div>
  //             </ToolTip>
  //           </RNPLDetailsItem>

  //           <RNPLDetailsItem
  //             name="Date of Birth"
  //             value={
  //               RNPLApllicant?.customer?.dateOfBirth ? (
  //                 <RedactInfo
  //                   info={moment(RNPLApllicant?.customer.dateOfBirth).format('DD-MM-YYYY')}
  //                   totalToRedact={6}
  //                   dataId={RNPLApllicant?.ref ?? ''}
  //                   dataType="policy"
  //                   clickable={!user.isInsurer() && true}
  //                 />
  //               ) : (
  //                 moment(RNPLApllicant?.customer.dateOfBirth).format('DD-MM-YYYY') ?? 'N/A'
  //               )
  //             }
  //           />

  //           <>
  //             <RNPLDetailsItem
  //               name="Do you have any other liabilities?"
  //               value={RNPLApllicant?.customer?.liability ? 'Yes' : 'No'}
  //             />
  //             {RNPLApllicant?.customer?.liability && (
  //               <>
  //                 <RNPLDetailsItem
  //                   name="How much are you owing?"
  //                   value={RNPLApllicant?.customer?.liabilityDebt ?? ''}
  //                 />
  //               </>
  //             )}
  //           </>
  //         </div>

  //         <div className="">
  //           <RNPLDetailsItem
  //             name="ID Type"
  //             value={RNPLApllicant?.customer?.idType ? capitalizeSentence(RNPLApllicant?.customer?.idType) : ''}
  //           />

  //           {!user.isInsurer() && (
  //             <RNPLDetailsItem
  //               name="ID Expiry Date"
  //               value={moment(RNPLApllicant?.customer?.idExpiry ?? '').format('DD-MM-YYYY')}
  //             />
  //           )}

  //           {!user.isInsurer() && (
  //             <>
  //               <RNPLDetailsItem
  //                 name="Occupation"
  //                 value={RNPLApllicant?.customer?.occupation ?? 'N/A'}
  //               />

  //               <RNPLDetailsItem
  //                 name="Employer Name"
  //                 value={RNPLApllicant?.customer?.employerName ?? 'N/A'}
  //               />
  //               <div className="flex flex-row justify-between ">
  //                 <span className="text-gray-600">Employer Address:</span>
  //                 <span className="truncate font-medium max-w-52">
  //                   {RNPLApllicant?.customer?.employerAddress ?? 'N/A'}
  //                 </span>
  //               </div>

  //               {/* <PolicyDetailsItem
  //                 name="Employer Address"
  //                 value={policy?.applicant?.employerAddress ?? 'N/A'}
  //               /> */}

  //               <RNPLDetailsItem
  //                 name="Employer Phone Number"
  //                 value={
  //                   getPhoneNumberFromShortCodeFormat(RNPLApllicant?.customer?.employerPhone) ??
  //                   'N/A'
  //                 }
  //               />

  //               <RNPLDetailsItem
  //                 name="Monthly Income Band"
  //                 value={RNPLApllicant?.customer?.monthlyIncomeBand ?? 'N/A'}
  //               />
  //             </>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const getQuotePlan = () => {
    return (
      <div className="mt-5">
        <span className="font-semibold">Quote Plan</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2  rounded-lg h-[230px] md:grid-cols-1 w-full '
          )}>
          <RNPLDetailsItem
            name={'Created On'}
            value={RNPLApllicant?.plan?.createOn ? moment(RNPLApllicant?.plan?.createOn).format('DD-MMM-YYYY') : 'N/A'}
          />

          <RNPLDetailsItem
            name={'Initial Deposit'}
            value={RNPLApllicant?.plan?.initialDeposit ? RNPLApllicant?.plan?.initialDeposit : 'N/A'}
          />
          <RNPLDetailsItem name={'Frequency'} value={RNPLApllicant.plan?.frequency ? RNPLApllicant.plan?.frequency : 'N/A'} />

          <RNPLDetailsItem
            name={'Installment Amount'}
            value={RNPLApllicant?.plan?.installmentAmount ? RNPLApllicant?.plan?.installmentAmount : 'N/A'}
          />
          <RNPLDetailsItem name={'Total Amount'} value={RNPLApllicant?.plan?.totalAmount ? RNPLApllicant?.plan?.totalAmount : 'N/A'} />
          <RNPLDetailsItem name={'Period'} value={RNPLApllicant?.plan?.period ? RNPLApllicant?.plan?.period : 'N/A'} />
        </div>
      </div>
    );
  };
  const getRNPLDetails = () => {
    return (
      <div className="mt-5">
        <span className="font-semibold">RNPL Details</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2  gap-5 rounded-lg h-[230px] md:grid-cols-1 w-full '
          )}>
          <div className="">
            <RNPLDetailsItem
              name={'Car Number'}
              value={RNPLApllicant.vehicles?.registrationNumber ?? 'N/A'}
            />
            <RNPLDetailsItem
              name={"Owner's Phone"}
              value={
                getPhoneNumberFromShortCodeFormat(RNPLApllicant.vehicles?.ownerPhoneNumber) ?? 'N/A'
              }
            />
            <RNPLDetailsItem
              name={'Repair Type'}
              value={capitalizeSentence(RNPLApllicant.repairType)}
            />
            <RNPLDetailsItem name={'Repair Cost'} value={RNPLApllicant?.repairCost} />
            <RNPLDetailsItem
              name={'Nature of Interest'}
              value={RNPLApllicant?.vehicles?.natureOfInterest ? RNPLApllicant?.vehicles?.natureOfInterest : 'N/A'}
            />
          
          </div>
        </div>
      </div>
    );
  };
  const getAboutVehicleDetails = (): JSX.Element => {
    return (
      <div className="mt-5">
        <span className="font-semibold">Vehicle</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2  gap-5 rounded-lg h-full md:grid-cols-2 w-full '
          )}>
          <div className="">
            <RNPLDetailsItem name="Vehicle Make" value={RNPLApllicant?.vehicles?.maker ?? 'N/A'} />
            <RNPLDetailsItem name="Vehicle Model" value={RNPLApllicant?.vehicles?.model ?? 'N/A'} />
            <RNPLDetailsItem
              name="Vehicle Type"
              value={RNPLApllicant?.vehicles?.type.name ?? 'N/A'}
            />
            <RNPLDetailsItem
              name="Vehicle Color"
              value={
                RNPLApllicant?.vehicles?.color
                  ? sentenceCase(RNPLApllicant?.vehicles?.color)
                  : 'N/A'
              }
            />
            <RNPLDetailsItem
              name="Chassis / VIN Number"
              value={RNPLApllicant?.vehicles?.chassisNumber ?? 'N/A'}></RNPLDetailsItem>
            <RNPLDetailsItem name="CC" value={RNPLApllicant?.vehicles?.cc ?? 'N/A'} />
            <RNPLDetailsItem
              name="Manufactured Year"
              value={RNPLApllicant?.vehicles?.manufacturedYear ?? 'N/A'}
            />
            <RNPLDetailsItem
              name="Registration Year"
              value={RNPLApllicant?.vehicles?.registrationYear ?? 'N/A'}
            />
            <RNPLDetailsItem
              name="Vehicle Usage"
              value={capitalizeSentence(RNPLApllicant?.vehicles?.vehicleUsage) ?? 'N/A'}
            />
          </div>

          <div className="">
            <RNPLDetailsItem
              name="Number of Passengers"
              value={RNPLApllicant?.vehicles?.maxSeat ?? 'N/A'}
            />
            <RNPLDetailsItem
              name="Vehicle Owner"
              value={RNPLApllicant?.vehicles?.ownerName ?? 'N/A'}
            />
            <RNPLDetailsItem
              name="Vehicle Owner's Phone"
              value={
                RNPLApllicant?.vehicles?.ownerPhoneNumber
                  ? getPhoneNumberFromShortCodeFormat(RNPLApllicant?.vehicles?.ownerPhoneNumber)
                  : 'N/A'
              }
            />
            <RNPLDetailsItem
              name="Vehicle Main Driver"
              value={RNPLApllicant?.vehicles?.driverName ?? 'N/A'}
            />

            <RNPLDetailsItem
              name="Vehicle Insured Value"
              value={
                numberWithCommas(
                  String(
                    RNPLApllicant?.vehicles?.issuedValue ??
                      RNPLApllicant?.Vehicles?.issuedValue.toFixed(2)
                  )
                ) ?? 'N/A'
              }
            />

            <RNPLDetailsItem
              name="Nature of Interest"
              value={
                RNPLApllicant?.vehicles?.natureOfInterest
                  ? capitalizeSentence(RNPLApllicant?.vehicles?.natureOfInterest)
                  : 'N/A'
              }
            />

            <RNPLDetailsItem
              name="Accessories Installed"
              value={RNPLApllicant?.vehicles?.installedAccessoriesDetails ?? 'N/A'}
            />

            <RNPLDetailsItem
              name="Accessories Installed Value"
              value={RNPLApllicant?.vehicles?.installedAccessoriesValue ?? 'N/A'}
            />

            <RNPLDetailsItem
              name="Vehicle Registration Number"
              value={RNPLApllicant?.vehicles?.registrationNumber ?? 'N/A'}
            />
          </div>
        </div>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="">
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div className="">
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      {
        Header: 'M',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Due Date',
            accessor: 'dueDate',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Paid Date',
            accessor: 'dateOfPayment',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Application Number',
            accessor: 'applicationNumber',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Channel',
            accessor: 'channel',
            Cell: (row: any) => {
              return (
                <span className="">{getFormattedPaymentChannel(row.cell.value) ?? 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Processor',
            accessor: 'processor',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Amount',
            accessor: 'preAmount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Charged Amount',
            accessor: 'amount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Processor Ref',
            accessor: 'reference',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
         
          {
            Header: 'Status',
            accessor: 'paymentStatus',
            Cell: (row: any) => {
              return <PaymentStatus row={row} actions={getRowActions(row.row.original)} />;
            }
          }
          // {
          //   id: 'Action',
          //   Header: 'Actions',
          //   Cell: (row: any) => {
          //     if (getActions(row.row.original)?.length > 0)
          //       return (
          //         <div className="cursor-pointer flex justify-center">
          //           <PopOnMe actions={getActions(row.row.original)}>
          //             <EllipsisVerticalIcon className="h-4 w-4" />
          //           </PopOnMe>
          //         </div>
          //       );
          //   }
          // }
        ]
      }
    ],
    []
  );
  const getPaymentActions = () => {
    let actions = [
      {
        label: 'Activate preapproval',
        callback: () => rnplPreapproval()
        
      },
      {
        label: 'Regenerate payment schedules',
        // callback: () => showGeneratePaymentObjectsModal('REGENERATE_PAYMENT_OBJECTS', invalidateQueries)
        callback: () => console.log('Regenerate payment schedules')
      },
      {
        label: 'Apply discount',
        // callback: () => applyDiscount()
        callback: () => console.log('Apply discount')
      }
    ];

    if (RNPLApllicant) {
      if (!payments || payments.length === 0) {
        actions.push({
          label: 'Generate payment schedules',
          // callback: () =>  showGeneratePaymentObjectsModal('GENERATE_PAYMENT_OBJECTS', invalidateQueries)
          callback: () => console.log('Generate payment schedules')
        });
      }

      // if (!policy.isFullPayment()) {
      //   actions.push(
      //     {
      //       label: 'Get website payment link',
      //       callback: () => generateWebsitePaymentLink()
      //     },
      //     {
      //       label: 'Activate recurring payment',
      //       callback: () => choosePaymentService()
      //     },
      //     {
      //       label: 'Complete all payments',
      //       callback: () => completeAllPayments()
      //     }
      //   );
      // }
    }

    return actions;
  };

  const paymentTable = () => {
    return (
      <>
        <div className="mt-10">
          <TabNavItem
            id="payments"
            title="Payments"
            activeTab={'Payments'}
            setActiveTab={setActiveTab}
            count={payments && payments?.length}
          />
          <div className="flex flex-col md:flex-row md:justify-between md:items-center ">
            <div>
              <span className="font-bold text-[15px]">Total Premium: </span>
              <span className="px-2">
              {RNPLApllicant?.plan?.totalAmount ? RNPLApllicant?.plan?.totalAmount.toFixed(2) : 'N/A'}
              </span>
            </div>
            <div>
              <span className="font-bold text-[15px]">Total Paid:</span>{' '}
              <span className="px-2">  { totalPaymentsAmount() ? totalPaymentsAmount().toFixed(2) : 0}</span>
            </div>
            <div>
              <span className="font-bold text-[15px]">Balance Remaining:</span>{' '}
              <span className="px-2">{ payments ? (RNPLApllicant?.plan?.totalAmount - totalPaymentsAmount()).toFixed(2) : 0}</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="font-bold text-[15px]">Discount Applied:</span>{' '}
              <div className="flex justify-center items-center">
                <div className="px-2">{/* {policy?.discount ? 'YES' : 'NO'} */}N/A</div>
                <div className="">
                  {/* {policy?.discount && (
                                  <Tooltip
                                    direction="bottom"
                                    bgColor="bg-gray-200"
                                    message={'View discount info'}>
                                    <MyPopOver
                                      trigger={<FaEye size={16} />}
                                      closeOnOutsideClick={true}>
                                      <DiscountInfoModal
                                        code={policy?.discount?.code}
                                        name={policy?.discount?.name}
                                        value={policy?.discount?.value}
                                        type={policy?.discount?.type}
                                      />
                                    </MyPopOver>
                                  </Tooltip>
                                )} */}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end md:flex-none">
              {getPaymentActions()?.length > 0 && (
                <PopOnMe actions={getPaymentActions()} popOptionClasses="!h-[35px] !min-w-[150px] ">
                  <div className="cursor-pointer py-1 px-2  border border-primary-main rounded w-fit">
                    Payment Actions
                  </div>
                </PopOnMe>
              )}
            </div>
          </div>
          <TabContent id={'payments'} activeTab={activeTab}>
            <div className="w-full md:overflow-y-auto max-h-[400px] relative">
              <GTable
                showCheckboxes
                selectionChanged={(selectedObjects: any[], selectedIndexes: number[]) => {
                  const paymentIdList = selectedObjects.map((payment) => payment.id);
                  // setSelectedPayments(paymentIdList);
                  // setSelectedPaymentIndexes(selectedIndexes);
                }}
                // actionElement={
                //   // screenWidth < 768 &&
                //   getPaymentActions()?.length > 0 && (
                //     <div className="flex justify-end w-full">
                //       <PopOnMe
                //         actions={getPaymentActions()}
                //         popOptionClasses="!h-[35px] !min-w-[150px]">
                //         <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                //           Payment Actions
                //         </div>
                //       </PopOnMe>
                //     </div>
                //   )
                // }
                stickyHeader
                columns={columns}
                data={orderPaymentsById}
                tableType="payment"
                showSearch={false}
                loading={loading}
                tableContainerId="tableId"
                classes='max-h-[300px]'
                headerClasses='first:hidden py-[0.5rem] text-left border-b-[1px] border-gray-300 rounded'
              />
            </div>
          </TabContent>
        </div>
      </>
    );
  };

  return (
    <>
      <Page title={`RNPL ${rnplId}`} className="">
        {loading ? (
          <div className="w-full text-center py-10 flex items-center justify-center h-[50vh] ">
            <Loader message={`Loading data. Please wait...`} brollyLoader />
          </div>
        ) : (
          <div className=" h-fit bg-white p-5">
            <div className="flex px-3 md:px-0 flex-col md:flex-row  justify-between">
              <div className="flex items-center justify-start gap-5">
                <div className="w-fit">
                  <BackButton
                    onClick={() => navigate(`${getDashboardBase()}/rnpl`, { replace: true })}
                  />
                </div>

                <div className="w-full min-w-fit text-2xl font-semibold">ID:{rnplId ?? 'N/A'} </div>

                <PolicyStatus
                  status={capitalizeSentence(RNPLApllicant?.reviewStage ?? 'N/A')}
                  classes="rounded-full px-2  w-[120px]"
                />
                
                  <div>
                    {' '}
                    {RNPLApllicant?.reviewStage !== POLICY_REVIEW_STATES.APPROVED && (<PencilSquareIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => editRNPL('CONTACT_INFO')}
                    />)}
                  </div>
                
              </div>

              <div className="flex flex-col min-[400px]:flex-row space-x-2 items-center mt-6 md:mt-0 justify-between">
                <div className=" flex items-center justify-center gap-x-3 mb-2 min-[400px]:mb-0">
                  {' '}
                  {getActions().length > 0 && (
                    <PopOnMe actions={getActions()}>
                      <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                        Actions
                      </div>
                    </PopOnMe>
                  )}
                </div>
              </div>
            </div>
            <div className="px-3 md:px-0">
              <div
                className={clsx(
                  'grid border border-gray-300 p-2 mt-10 rounded-lg h-full',
                  user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-3 grid-cols-1 gap-5'
                )}>
                <RNPLDetailsItem name={'Applicant Name'}>
                  <Link
                    to={`${getDashboardBase()}/customers/${RNPLApllicant.customer.id}`}
                    target="blank_"
                    className="hover:underline text-link-main">
                    {capitalizeSentence(
                      RNPLApllicant.customer?.firstName + ' ' + RNPLApllicant.customer?.lastName
                    ) ?? 'N/A'}
                  </Link>
                </RNPLDetailsItem>
                <RNPLDetailsItem
                  name={'Applicant Phone'}
                  value={getPhoneNumberFromShortCodeFormat(RNPLApllicant.customer?.phone) ?? 'N/A'}
                />
                <RNPLDetailsItem
                  name={'Application Number'}
                  value={RNPLApllicant.applicationNumber}
                />
              </div>
              <div className="flex flex-col gap-3 min-[1200px]:flex-row">
                <div className="flex flex-col min-[1200px]:w-2/3">
                  <div className="">{getProposer()}</div>
                  <div className=" "> {getAboutVehicleDetails()}</div>
                </div>

                {/* Activity Section */}
                <div className="md:col-span-1 w-full  min-[1200px]:w-1/3 mt-8">
                  <span className="font-semibold pl-4"> Activity feed</span>
                  <div className=" py-5 overflow-y-auto h-full md:max-h-[34rem]">
                    <ActivityStepper
                      activityStreams={RNPLApllicant.activityStreams}
                      orientation="vertical"
                      currentStep={0}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1  lg:grid-cols-3 gap-4">
                <div className="md:w-full"> {getRNPLDetails()}</div>
                <div className="md:w-full"> {getQuotePlan()}</div>
                <div className="md:w-full">{otherInfo()}</div>
              </div>

              {/* <div className="flex flex-row gap-5">
                <div
                  className={clsx(
                    'grid border border-gray-300 p-2 mt-10 rounded-lg h-full',
                    user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-1 grid-cols-1 w-full gap-5'
                  )}>
                  <RNPLDetailsItem name={'Estimate Picture'} value={'N/A'}>
                    {RNPLApllicant?.estimateDocument && RNPLApllicant?.estimateDocument?.length > 0 && (
                        <div className="w-full">
                          <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 items-center gap-5">
                            {RNPLApllicant.documents.map(
                              (document: IPolicyDocument) =>
                                (!user.isInsurer() ||
                                  (user.isInsurer() &&
                                    document.docType !==
                                      POLICY_DOCUMENT_TYPES.PAYMENT_AGREEMENT)) && (
                                  <div key={document.title}>
                                    <div className="min-h-[90px]">
                                      {getDocumentPlaceholder(document.docType)}
                                    </div>
                                    <div className="w-full bg-black h-[40px] flex items-center">
                                      <div className="flex justify-between items-center w-full px-4">
                                        <div className="flex items-center space-x-2 w-full flex-start"> 
                                          <span className="text-white text-[16px] whitespace-nowrap">
                                            {document.title}
                                          </span>
                                        </div>
                                        <div className="flex items-center space-x-2 flex-end">
                                          <EyeIcon
                                            className="text-white w-[16px] cursor-pointer"
                                            onClick={() => previewDocument(document)}
                                          />
                                          <HiDownload
                                            className="text-white w-[16px] cursor-pointer"
                                            onClick={() => downloadDocument(document)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      )}
                  </RNPLDetailsItem>
                </div>
                <div
                  className={clsx(
                    'grid border border-gray-300 p-2 mt-10 rounded-lg h-full',
                    user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-1 grid-cols-1 w-full gap-5'
                  )}>
                  <RNPLDetailsItem name={'Picture of Damaged Area'} value={'N/A'} />
                </div>
                <div
                  className={clsx(
                    'grid border border-gray-300 p-2 mt-10 rounded-lg h-full',
                    user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-1 grid-cols-1 w-full gap-5'
                  )}>
                  <RNPLDetailsItem name={'Roadworthy Certificate'} value={'N/A'} />
                </div>
              </div> */}
            </div>
            {paymentTable()}
          </div>
        )}

        <ModalComponent />
      </Page>
    </>
  );
};

export default RNPLApplicantDetails;

type RNPLDetailsItemProps = {
  name?: string;
  value?: string | number | JSX.Element;
  children?: React.ReactNode;
};

const RNPLDetailsItem = ({ name, value, children }: RNPLDetailsItemProps) => {
  return (
    <div className={`flex flex-row py-1 ${name && 'justify-between'}`}>
      <div className="text-gray-600 ">{name && `${name}:`}</div>
      <div className="flex items-center gap-1">
        <span className="truncate text-[14px] font-medium whitespace-nowrap">{value}</span>
      </div>
      {children}
    </div>
  );
};

const getDocumentPlaceholder = (type: POLICY_DOCUMENT_TYPES | undefined): JSX.Element => {
  switch (type) {
    case POLICY_DOCUMENT_TYPES.ROADWORTHY:
      return (
        <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center"></div>
      );

    default:
      return (
        <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center"></div>
      );
  }
};

const previewDocument = (document: IPolicyDocument) => {
  window.open(document.docURL, '_blank');
};

const downloadDocument = (document: IPolicyDocument) => {
  downloadPolicyDocument(document.id)
    .then((response: any) => {
      if (response) {
        fileDownload(response.data, `Brolly Africa - ${document.docType}.pdf`);
      }
    })
    .catch(() => {
      toast.error('Failed to download document. Please try again later');
    });
};


function generatePaymentLink(id: any) {
  console.log('generatePaymentLink')
}

function sendPaymentNotification(payment: any) {
  console.log('sendPaymentNotification')
}

function InitiateHubtelDirectCharge(payment: any) {
  console.log('InitiateHubtelDirectCharge')
}

function showEditPaymentModal(payment: any) {
  console.log('showEditPaymentModal')
}

function showPaymentApprovalModal(payment: any) {
 console.log('showPaymentApprovalModal')
}


const showHubtelPreapprovalModal = (RNPL:any) => {
  
  if (RNPL) {
    
  }
};

function updateModalStates(arg0: { show: boolean; children: import("react/jsx-runtime").JSX.Element; size: string; }) {
  throw new Error('Function not implemented.');
}

