import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import Button from '../form-control/Button';
import Input from '../form-control/Input';
import TextArea from '../form-control/TextArea';
import * as Yup from "yup";
import { slackNotifyReport } from '../../api/requests';
import clsx from 'clsx';
import SelectOption from '../form-control/SelectOption';
import FileUpload from '../elements/FileUpload/FileUpload';
import useImage from '../../hooks/imageHook';
import useUserAccount from '../../hooks/userAccountHook';
import { fromPairs } from 'lodash';

interface IReportResponseData {
  type: string;
  environment: string;
  subject: string;
  description: string;
  from: string | undefined;
}
const ReportFeatureBug = ():JSX.Element => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [IdentifierPhoto, setIdentifierPhoto] = useState<any>(); 
    const btnSubmitRef = useRef<HTMLDivElement>(null);
    const { user } = useUserAccount();

    const sendSlackNotification = (reportResponse: IReportResponseData) => {
        const data: string = JSON.stringify({
          text: [
            `Type: *_${reportResponse?.type}_*`,
            `Environment: ${reportResponse?.environment}`,
            `Subject: ${reportResponse?.subject}`,
            `Description: ${reportResponse?.description}`,
            `Url: ${window.location.href}`,
            `${reportResponse?.type == 'Feature Requesting' ? 'Feature requester' : 'Issue reporter'}: ${reportResponse?.from}`
          ].join('\n')
        });
        slackNotifyReport(data);
      };
 
    const handleSubmit = async (values: IReportResponseData) => {

      const reportReponse: IReportResponseData = {
        type: values.type,
        environment: values.environment,
        subject: values.subject,
        description: values.description,
        from:user && user?.fullName
      };
      sendSlackNotification(reportReponse);
      setisVisible(isVisible);
      setTimeout(() => {
        setisVisible(!isVisible);
      }, 500); 
  
    };



const [isVisible, setisVisible] = useState(true)
const [hideReportModal, setHideReportModal] = useState('hidden')


  return (
    <div className={`w-full py-5 bg-white ${!isVisible ? hideReportModal :''}`}>
      <div className="flex justify-between mb-4 -sm px-5">
        <div>
          <h2 className={`font-semibold transition duration-300 ease-in-out  text-lg`}>
            Create Report
          </h2>
        </div>
      </div>
      <hr />
      <div className="py-[43px] px-0 md:px-14 w-full">
        <Formik
          initialValues={{
            type:'',
            environment:'',
            subject:'',
            description:'',
            from: user?.fullName
          }}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={Yup.object().shape({
            type: Yup.string().trim().required('Type required'),
            environment: Yup.string().trim().required('Environment required'),
            subject: Yup.string().trim().required('Subject required'),
            description: Yup.string().trim().required('Description required')
          })}
          onSubmit={(values) => {
            handleSubmit(values);
            setSubmitting(true)
          }}>
          {({
            values,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            touched
          }) => (
            <form className="" onSubmit={handleSubmit}>
              <div className="w-full flex flex-col space-y-8">
                <div className="flex flex-col space-y-6 text-black">
                  <SelectOption
                    value1="Feature"
                    value2="Issue"
                    label="Select Report Type"
                    required
                    name="type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    error={touched.type && errors.type ? String(errors.type) : undefined}
                    helperText={touched.type && errors.type ? String(errors.type) : undefined}
                  />
                  <Input
                    type="text"
                    name="environment"
                    value={values.environment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Environment"
                    required
                    error={
                      touched.environment && errors.environment
                        ? String(errors.environment)
                        : undefined
                    }
                    helperText={
                      touched.environment && errors.environment
                        ? String(errors.environment)
                        : undefined
                    }
                    className="rounded"
                  />
                  <Input
                    type="text"
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Subject"
                    required
                    error={touched.subject && errors.subject ? String(errors.subject) : undefined}
                    helperText={
                      touched.subject && errors.subject ? String(errors.subject) : undefined
                    }
                    className="rounded"
                  />
                  <TextArea
                    name={'description'}
                    label="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    error={
                      touched?.description && errors.description
                        ? String(errors.description)
                        : undefined
                    }
                    helperText={
                      touched?.description && errors.description
                        ? String(errors.description)
                        : undefined
                    }
                  />
                  
                  {/* <Input
                    type="file"
                    name="identifier"
                    value={values.identifier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Identifier"
                    className="rounded"
                  /> */}

                  {/* <FileUpload
                    onFileLoad={(images: any) => {
                      if (images && images[0]) {
                        setThumbnail(images[0]); 
                      }
                    }}
                    showFileMax={false}
                    onFileDeleted={() => {
                      setIdentifierPhoto(null);
                    }}
                    type='image'
                    title="identifier"
                    label="Identifier"
                    cropImage={false}
                    // maxHeight={550}
                    // maxWidth={1024}
                    imageContainerHeight={'300px'}
                    imageAlt={'Identifier'}
                    savedImage={identifier}
                  /> */}
                </div>

                {/* Action Buttons */}

                <div className="flex-col-reverse gap-5 flex md:flex-row mt-10">
                  <Button
                    label="Submit"
                    type="submit"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main"
                    curved
                    bgColor="primary"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};



export default ReportFeatureBug;
